import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Post from "../types/api/Post"
import { useBlogBackgroundImage } from "../utils/blog"
import { h3, h4, h5, h6 } from "../components/styles"
import { breakpoints } from "../constants/breakpoints"
import RelatedPosts from "./RelatedPosts"
import PageContainer from "../components/PageContainer"
import RichText from "../components/RichText"

interface Data {
  strapiPost: Post
}

interface Props {
  data: Data
}

const Content = styled.div`
  margin: 3rem 0;

  ${breakpoints("sm")`
    padding: 0 3rem;
  `}

  ${breakpoints("md")`
    padding: 0 5rem;
  `}

  ${breakpoints("lg")`
    padding: 0;
  `}

  p + p,
  p + h3,
  p + h4,
  p + h5,
  p + h6 {
    margin-top: 1rem;
  }
  h3 {
    ${h3}
  }
  h4 {
    ${h4}
  }
  h5 {
    ${h5}
  }
  h6 {
    ${h6}
  }

  ul,
  ol {
    padding-left: 2.5rem;
  }

  ul li {
    list-style: disc;
  }

  ol li {
    list-style: decimal;
  }
`

export default function Blog({
  data: {
    strapiPost: {
      title,
      body,
      headerImage,
      relatedPosts,
      excerpt,
      thumbnail: {
        localFile: { publicURL: thumbnailUrl },
      },
    },
  },
}: Props) {
  const fluid = headerImage ? null : useBlogBackgroundImage()
  const meta = [
    // Sets the thumbnail as SEO image
    {
      name: "og:image",
      content: thumbnailUrl,
    },
  ]
  return (
    <Layout>
      <Seo title={title} meta={meta} description={excerpt} />
      <Hero
        title={title}
        fluid={fluid}
        image={null}
        backgroundImageData={headerImage}
      />
      <PageContainer>
        <Content>
          <RichText text={body} />
        </Content>
      </PageContainer>
      <RelatedPosts relatedPosts={relatedPosts} />
    </Layout>
  )
}
