import React from "react"
import styled from "styled-components"

import ContentArea from "../components/ContentArea"
import Posts from "../components/Posts"
import { Heading } from "../components/styles"
import Post from "../types/api/Post"

interface Props {
  relatedPosts: Post["relatedPosts"]
}

const StyledContentArea = styled(ContentArea)`
  padding: 1rem 0;
`

export default function RelatedPosts({ relatedPosts = [] }: Props) {
  return (
    <section>
      <StyledContentArea alternate>
        <Heading center>Related Posts</Heading>
        <Posts posts={relatedPosts} />
      </StyledContentArea>
    </section>
  )
}
